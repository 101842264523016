import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LanguageContext from "../languageSwitch/LanguageContext";
import LogoButton from "../button/components/LogoButton";
// import UserButton from "../button/components/UserButton";
// import BookButton from "../button/components/BookButton";
// import HomeButton from "../button/components/HomeButton";
// import SchoolButton from "../button/components/SchoolButton";
// import PersonInfoButton from "../button/components/PersonInfoButton";
import LoadingRing from "./components/LoadingRing";
import Footer from "./components/Footer";
import CourseTestButton from "../button/components/CourseTestButton";
import CourseButton from "../button/components/CourseButton";
import MultipleVideoPlayer from "./components/MultipleVideoPlayer";
import CircleButtonSmall from "../button/components/CircleButtonSmall";

import translations from "../translations.json";
import circleButtonGrid from "../circleButtonGrid.json";
import videoPath from "../videoPath.json";
import "./css/SingleVideoPage.css";

const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

const dayToIndexMap = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
};

const typeToIndexMap = {
    create: 0,
    story: 1,
    exercise: 2,
    game: 3,
};

function SingleVideoPage({ jsonFileName }) {
    const { language } = useContext(LanguageContext);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const getDayOfWeek = () => {
        const currentDay = new Date().getDay();
        return currentDay === 6 || currentDay === 0
            ? "Monday"
            : daysOfWeek[currentDay];
    };

    const [selectedRightButton, setSelectedRightButton] = useState(
        getDayOfWeek()
    );
    const [selectedLeftButton, setSelectedLeftButton] = useState("create");
    const [selectedCircleButton, setSelectedCircleButton] = useState(0);

    const handleVideoCanPlay = () => {
        setIsVideoLoaded(true);
    };

    const leftButtons = ["create", "story", "exercise", "game"];

    const generateCategoryData = (days, prefix, translations, language) => ({
        buttons: days.map((day) => ({
            key: day,
            videoPath: `path_to_${prefix}${day}.mp4`,
            title: translations[language][day],
        })),
    });

    const videosMapping = {
        create: {
            ...generateCategoryData(
                ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                "video1",
                translations,
                language
            ),
            circleButtonsCount: [5, 2, 1, 2, 2],
        },
        story: {
            ...generateCategoryData(
                ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                "video2",
                translations,
                language
            ),
            circleButtonsCount: [2, 6, 1, 2, 1],
        },
        exercise: {
            ...generateCategoryData(
                ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                "video3",
                translations,
                language
            ),
            circleButtonsCount: [1, 5, 2, 4, 4],
        },
        game: {
            ...generateCategoryData(
                ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                "video4",
                translations,
                language
            ),
            circleButtonsCount: [1, 1, 1, 1, 1],
        },
    };

    const handleLeftButtonClick = (button) => {
        setSelectedLeftButton(button);
        setSelectedRightButton(getDayOfWeek());
        setSelectedCircleButton(0); // 預設選擇第一個圓形按鈕
    };

    const handleRightButtonClick = (button) => {
        setSelectedRightButton(button);
        setSelectedCircleButton(0);
    };

    const handleCircleButtonClick = (index) => {
        setSelectedCircleButton(index);
    };

    useEffect(() => {
        setIsVideoLoaded(false); // 每次这些值改变时，重置视频加载状态
    }, [selectedLeftButton, selectedRightButton, selectedCircleButton]);

    useEffect(() => {
        const getDayOfWeek = () => {
            const currentDay = new Date().getDay();
            return currentDay === 6 || currentDay === 0
                ? "Monday"
                : daysOfWeek[currentDay];
        };
        setSelectedRightButton(getDayOfWeek());
    }, [selectedLeftButton]);

    return (
        <Container fluid className="single_video_page_container_flex p-4">
            <div className="single_video_page_content">
                <Row>
                    <Col md="2" className="single_video_page_logo">
                        <LogoButton />
                    </Col>
                    {/* <Col>
                    <UserButton/>
                    <SchoolButton/>
                    <PersonInfoButton/>
                    <BookButton/>
                    <HomeButton/>
                </Col> */}
                </Row>
                <Row className="pt-2 main-container">
                    <Col md="2" className="buttonCol order-md-1 order-1">
                        <div className="single_video_page_button_left">
                            {leftButtons.map((button, index) => (
                                <CourseButton
                                    key={index}
                                    onClick={() =>
                                        handleLeftButtonClick(button)
                                    }
                                    selected={button === selectedLeftButton}
                                    label={translations[language][button]}
                                />
                            ))}
                        </div>
                    </Col>
                    <Col md="8" className="order-md-2 order-2">
                        <div className="single_video_page_player_container">
                            {!isVideoLoaded && (
                                <div className="single_video_page_loading_ring">
                                    <LoadingRing />
                                </div>
                            )}
                            <div
                                style={{
                                    display: isVideoLoaded ? "block" : "none",
                                }}
                            >
                                <MultipleVideoPlayer
                                    key={
                                        videoPath[jsonFileName][
                                            selectedLeftButton
                                        ][selectedRightButton][
                                            selectedCircleButton
                                        ]
                                    }
                                    sources={[
                                        videoPath[jsonFileName][
                                            selectedLeftButton
                                        ][selectedRightButton][
                                            selectedCircleButton
                                        ],
                                    ]}
                                    onCanPlay={handleVideoCanPlay}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md="2" className="buttonCol order-md-3 order-1">
                        <div className="single_video_page_button_right">
                            {selectedLeftButton &&
                                selectedRightButton &&
                                videosMapping[selectedLeftButton].buttons.map(
                                    (button) => (
                                        <CourseButton
                                            key={button.key}
                                            onClick={() =>
                                                handleRightButtonClick(
                                                    button.key
                                                )
                                            }
                                            selected={
                                                button.key ===
                                                selectedRightButton
                                            }
                                            label={button.title}
                                        />
                                    )
                                )}
                            <CourseTestButton jsonFileName={jsonFileName} />
                        </div>
                    </Col>
                    <Col className="buttonCol order-md-4 order-2">
                        <div className="single_video_page_circle_small_button">
                            {Array.from({
                                length: circleButtonGrid[jsonFileName][
                                    typeToIndexMap[selectedLeftButton]
                                ][dayToIndexMap[selectedRightButton]],
                            }).map((_, index) => (
                                <CircleButtonSmall
                                    key={index}
                                    selected={index === selectedCircleButton}
                                    onClick={() =>
                                        handleCircleButtonClick(index)
                                    }
                                    label={index + 1}
                                />
                            ))}
                        </div>
                    </Col>
                </Row>
            </div>
            <Row className="pt-4 single_video_page_footer">
                <Col>
                    <Footer />
                </Col>
            </Row>
        </Container>
    );
}

export default SingleVideoPage;
