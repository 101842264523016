import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import LanguageContext from "../languageSwitch/LanguageContext";
import LogoButton from "../button/components/LogoButton";
import LoadingRing from "./components/LoadingRing";
import Footer from "./components/Footer";
import CourseTestButton from "../button/components/CourseTestButton";
import CourseButton from "../button/components/CourseButton";
import MultipleVideoPlayer from "./components/MultipleVideoPlayer";
import CircleButtonSmall from "../button/components/CircleButtonSmall";

import translations from "../translations.json";
import circleButtonGrid from "../circleButtonGrid.json";
import videoPath from "../videoPath.json";
import "./css/SingleVideoPage.css";

//

const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

const dayToIndexMap = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
};

const typeToIndexMap = {
    create: 0,
    story: 1,
    exercise: 2,
    game: 3,
};

const activities = [
    { time: "9:30", icon: "🎭", activity: "靜心坐" },
    { time: "10:30", icon: "🏋️", activity: "小跑步" },
    { time: "12:00", icon: "🍽️", activity: "小合併" },
    { time: "12:00", icon: "🍽️", activity: "吃飯" },
];

function ActionHomePage({ jsonFileName }) {
    const { language } = useContext(LanguageContext);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [completed, setCompleted] = useState(
        Array(activities.length).fill(false)
    );

    const getDayOfWeek = () => {
        const currentDay = new Date().getDay();
        return currentDay === 6 || currentDay === 0
            ? "Monday"
            : daysOfWeek[currentDay];
    };

    const [selectedRightButton, setSelectedRightButton] = useState(
        getDayOfWeek()
    );
    const [selectedLeftButton, setSelectedLeftButton] = useState("create");
    const [selectedCircleButton, setSelectedCircleButton] = useState(0);

    const handleVideoCanPlay = () => {
        setIsVideoLoaded(true);
    };

    const leftButtons = [
        "SensoryIntegration",
        "BeautyAnimate",
        "ChinesePronunciation",
        "LittleClock",
    ];

    const toggleComplete = (index) => {
        const newCompleted = [...completed];
        newCompleted[index] = !newCompleted[index];
        setCompleted(newCompleted);
    };

    const generateCategoryData = (days, prefix, translations, language) => ({
        buttons: days.map((day) => ({
            key: day,
            videoPath: `path_to_${prefix}${day}.mp4`,
            title: translations[language][day],
        })),
    });

    const videosMapping = {
        create: {
            ...generateCategoryData(
                ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                "video1",
                translations,
                language
            ),
            circleButtonsCount: [5, 2, 1, 2, 2],
        },
        story: {
            ...generateCategoryData(
                ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                "video2",
                translations,
                language
            ),
            circleButtonsCount: [2, 6, 1, 2, 1],
        },
        exercise: {
            ...generateCategoryData(
                ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                "video3",
                translations,
                language
            ),
            circleButtonsCount: [1, 5, 2, 4, 4],
        },
        game: {
            ...generateCategoryData(
                ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                "video4",
                translations,
                language
            ),
            circleButtonsCount: [1, 1, 1, 1, 1],
        },
    };

    const handleLeftButtonClick = (button) => {
        setSelectedLeftButton(button);
        setSelectedRightButton(getDayOfWeek());
        setSelectedCircleButton(0); // 預設選擇第一個圓形按鈕
    };

    const handleRightButtonClick = (button) => {
        setSelectedRightButton(button);
        setSelectedCircleButton(0);
    };

    const handleCircleButtonClick = (index) => {
        setSelectedCircleButton(index);
    };

    useEffect(() => {
        setIsVideoLoaded(false); // 每次这些值改变时，重置视频加载状态
    }, [selectedLeftButton, selectedRightButton, selectedCircleButton]);

    useEffect(() => {
        const getDayOfWeek = () => {
            const currentDay = new Date().getDay();
            return currentDay === 6 || currentDay === 0
                ? "Monday"
                : daysOfWeek[currentDay];
        };
        setSelectedRightButton(getDayOfWeek());
    }, [selectedLeftButton]);

    return (
        <Container fluid className="single_video_page_container_flex p-4">
            <div className="single_video_page_content">
                <Row>
                    <Col md="2" className="single_video_page_logo">
                        <LogoButton />
                    </Col>
                </Row>
                <Row className="pt-2 main-container">
                    <Col md="2" className="buttonCol order-md-1 order-1">
                        <div className="single_video_page_button_left">
                            {leftButtons.map((button, index) => (
                                <CourseButton
                                    key={index}
                                    onClick={() =>
                                        handleLeftButtonClick(button)
                                    }
                                    selected={button === selectedLeftButton}
                                    label={translations[language][button]}
                                />
                            ))}
                        </div>
                    </Col>
                    <Col md="8" className="order-md-2 order-2">
                        <div className="single_video_page_player_container">
                            {!isVideoLoaded && (
                                <div className="single_video_page_loading_ring">
                                    <LoadingRing />
                                </div>
                            )}
                            <div
                                style={{
                                    display: isVideoLoaded ? "block" : "none",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <MultipleVideoPlayer
                                    key={
                                        videoPath[jsonFileName][
                                            selectedLeftButton
                                        ][selectedRightButton][
                                            selectedCircleButton
                                        ]
                                    }
                                    // sources={[
                                    //     videoPath[jsonFileName][
                                    //         selectedLeftButton
                                    //     ][selectedRightButton][
                                    //         selectedCircleButton
                                    //     ],
                                    // ]}
                                    sources={[
                                        "https://www.w3schools.com/html/mov_bbb.mp4",
                                    ]}
                                    onCanPlay={handleVideoCanPlay}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col
                        md="2"
                        className="order-md-3 order-1 d-flex flex-column activity-section"
                    >
                        {/* 可滾動的活動列表 */}
                        <div className="activity-list-scrollable">
                            {activities.map((item, index) => (
                                <div
                                    key={index}
                                    className={`activity-card ${
                                        completed[index] ? "completed" : ""
                                    }`}
                                >
                                    <div className="activity-index">
                                        {index + 1}
                                    </div>
                                    <div className="activity-content">
                                        <span className="activity-time">
                                            {item.time}
                                        </span>
                                        <span className="activity-name">
                                            {item.activity}
                                        </span>
                                    </div>
                                    <div className="activity-icon">
                                        {item.icon}
                                    </div>
                                    <button
                                        onClick={() => toggleComplete(index)}
                                        className={`activity-button ${
                                            completed[index]
                                                ? "completed"
                                                : "not-completed"
                                        }`}
                                    >
                                        {completed[index] ? "✓" : "○"}
                                    </button>
                                </div>
                            ))}
                        </div>

                        {/* 存錢筒與最後一個按鈕對齊 */}
                        <div className="piggy-bank-container-fixed">
                            <div className="piggy-bank">
                                <span className="piggy-icon">🐷</span>
                                <span className="piggy-count">
                                    {completed.filter(Boolean).length}
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="buttonCol order-md-4 order-2">
                        <div className="single_video_page_circle_small_button">
                            {Array.from({
                                length: circleButtonGrid[jsonFileName][
                                    typeToIndexMap[selectedLeftButton]
                                ][dayToIndexMap[selectedRightButton]],
                            }).map((_, index) => (
                                <CircleButtonSmall
                                    key={index}
                                    selected={index === selectedCircleButton}
                                    onClick={() =>
                                        handleCircleButtonClick(index)
                                    }
                                    label={index + 1}
                                />
                            ))}
                        </div>
                    </Col>
                </Row>
            </div>
            <Row className="pt-4 single_video_page_footer">
                <Col>
                    <Footer />
                </Col>
            </Row>
        </Container>
    );
}

export default ActionHomePage;
